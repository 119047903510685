import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo2.png';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = value => {
    setActiveLink(value);
    setExpanded(false); // Ensure this is called to collapse the navbar
  };

  const toggleNavbar = () => setExpanded(!expanded); // Toggle the expansion state

  // Animation variants for the logo
  const logoVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const buttonVariants = {
    hover: { scale: 1.1 },
  };

  return (
    <Navbar
      expand='lg'
      className={`${scrolled || expanded ? 'scrolled' : ''}`}
      onToggle={toggleNavbar}
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href='/'>
          <motion.img
            src={logo}
            className='navbar-logo'
            alt='logo'
            initial='hidden'
            animate='visible'
            variants={logoVariants}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto'>
            <Nav.Link
              href='/'
              className={
                activeLink === 'home' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/bounty-hunters'
              className={
                activeLink === 'bountyhunters'
                  ? 'active navbar-link'
                  : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('bountyhunters')}
            >
              Bounty Hunters
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/ai-builders'
              className={
                activeLink === 'aibuilders'
                  ? 'active navbar-link'
                  : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('aibuilders')}
            >
              AI Builders
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/about-us'
              className={
                activeLink === 'about' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('about')}
            >
              About Us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/media-page'
              className={
                activeLink === 'media' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('media')}
            >
              Media
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/faq'
              className={
                activeLink === 'faq' ? 'active navbar-link' : 'navbar-link'
              }
              onClick={() => onUpdateActiveLink('faq')}
              target='_blank'
              rel='noopener noreferrer'
            >
              FAQs
            </Nav.Link>
          </Nav>
          <span className='navbar-text'>
            <Nav.Link
              as={Link}
              to='/waitlist'
              className={activeLink === 'waitlist' ? 'active' : ''}
              onClick={() => onUpdateActiveLink('waitlist')}
            >
              <motion.button
                className='vvd'
                whileHover='hover'
                variants={buttonVariants}
              >
                <span>Join Us</span>
              </motion.button>
            </Nav.Link>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
