import { logEvent } from 'firebase/analytics';
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { analytics, db } from '../firebaseConfig';
import '../styles/WaitlistMain.css'; // Ensure this is the correct relative path to your CSS file

const googleMapsToken = process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN;

const Waitlistmain = () => {
  const [dob, setDob] = useState('');
  const [location, setLocation] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [referrerID, setReferrerID] = useState(null);

  // Errors
  const emailErrorMessageRef = useRef(null);
  const googleEmailErrorMessageRef = useRef(null);
  const ageErrorMessageRef = useRef(null);
  const formErrorMessageRef = useRef(null);
  const mobileErrorMessageRef = useRef(null);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [googleEmailValidationMessage, setGoogleEmailValidationMessage] =
    useState('');
  const [ageValidationMessage, setAgeValidationMessage] = useState('');
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [mobileValidationMessage, setMobileValidationMessage] = useState('');

  // loading
  const [loading, setLoading] = useState(false);

  // Success
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [mobile, setMobile] = useState('');

  const locationInputRef = useRef(null);
  const autocomplete = useRef(null);

  const calculateAge = dob => {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  // Function to handle the date change
  const handleDobChange = e => {
    const selectedDob = e.target.value;
    setDob(selectedDob);
    const age = calculateAge(selectedDob);
    if (age < 18) {
      setAgeValidationMessage('You must be at least 18 years old.');
    } else {
      setAgeValidationMessage('');
    }
  };

  const handleLocationChange = e => {
    setLocation(e.target.value);
  };

  useEffect(() => {
    // Function to initialize the Autocomplete
    const initializeAutocomplete = () => {
      if (locationInputRef.current) {
        autocomplete.current = new window.google.maps.places.Autocomplete(
          locationInputRef.current,
          { types: ['geocode'] }
        );

        autocomplete.current.addListener('place_changed', handlePlaceSelect);
      }
    };

    // Append the Google Maps script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsToken}&libraries=places`;

    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = initializeAutocomplete; // Initialize Autocomplete once script is loaded
    script.onerror = () => console.error('Google Maps script failed to load.'); // Error handling for script load failure

    return () => {
      // Cleanup function to remove script and listener
      script.removeEventListener('load', initializeAutocomplete);
      script.removeEventListener('error', script.onerror);
      document.body.removeChild(script);
    };
  }, []);

  // New useEffect to capture utm_source
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrerID = urlParams.get('utm_source');
    setReferrerID(referrerID || null);
  }, []);

  const handlePlaceSelect = () => {
    const place = autocomplete.current.getPlace();
    let addressCity = '';
    let addressState = '';
    let addressZip = '';
    let addressCountry = '';
    let addressStreet = '';

    if (place.address_components) {
      // Extract the street name and number
      const streetNumberComponent = place.address_components.find(component =>
        component.types.includes('street_number')
      ) || { long_name: '' };
      const streetNameComponent = place.address_components.find(component =>
        component.types.includes('route')
      ) || { long_name: '' };

      // Combine street number and name for full street address
      addressStreet =
        `${streetNumberComponent.long_name} ${streetNameComponent.long_name}`.trim();

      // Loop through each component for the rest of the address and set the corresponding state
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'locality': // city
            addressCity = component.long_name;
            break;
          case 'administrative_area_level_1': // state
            addressState = component.long_name;
            break;
          case 'postal_code': // zip code
            addressZip = component.long_name;
            break;
          case 'country': // country
            addressCountry = component.long_name;
            break;
          default:
            break;
        }
      }

      // Update the states
      setLocation(addressStreet); // Now this contains the full street address
      setCity(addressCity);
      setState(addressState);
      setZip(addressZip);
      setCountry(addressCountry);
    } else {
      alert('No address details available.');
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, y: 50 },
  };

  const countries = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'The Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo, Democratic Republic of the',
    'Congo, Republic of the',
    'Costa Rica',
    'Côte d’Ivoire',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor (Timor-Leste)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'The Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea, North',
    'Korea, South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia, Federated States of',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar (Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Sudan, South',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];

  // Handle the form submission event
  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true); // Set loading true right after form submission
    logEvent(analytics, 'form_submission_started', { form: 'waitlist' });

    try {
      await addDoc(collection(db, 'waitlist'), formData);
      logEvent(analytics, 'form_submission_success', {
        form_name: 'Waitlist Form',
      });
      setIsSubmitted(true);
    } catch (error) {
      setFormErrorMessage('Submission failed. Please try again.');
      logEvent(analytics, 'form_submission_failure', {
        form_name: 'Waitlist Form',
        error: error.message,
      });
    }

    // Clear the error message at the beginning of the submission attempt
    setEmailValidationMessage('');
    setGoogleEmailValidationMessage('');
    setAgeValidationMessage('');
    setFormErrorMessage('');
    setMobileValidationMessage('');

    function trimFieldValue(fieldValue) {
      return (fieldValue || '').trim();
    }

    // Create an object to hold the form data
    const formData = {
      firstName: trimFieldValue(event.target.firstName?.value || ''),
      lastName: trimFieldValue(event.target.lastName?.value || ''),
      email: trimFieldValue(event.target.email?.value || ''),
      googleEmail: trimFieldValue(event.target.googleEmail?.value || ''),
      mobile: trimFieldValue(event.target.mobile?.value || ''),
      dob: trimFieldValue(event.target.dob?.value || ''),
      gender: trimFieldValue(event.target.gender?.value || ''),
      location: trimFieldValue(event.target.location?.value || ''),
      country: trimFieldValue(event.target.country?.value || ''),
      city: trimFieldValue(event.target.city?.value || ''),
      state: trimFieldValue(event.target.state?.value || ''),
      zipcode: trimFieldValue(event.target.zipcode?.value || ''),
      subjectRelease: event.target.subjectRelease?.checked || false,
      termsOfService: event.target.termsOfService?.checked || false,
      privacyPolicy: event.target.privacyPolicy?.checked || false,
      marketingEmails: event.target.marketingEmails?.checked || false,
      referrerID: referrerID,
      createdAt: serverTimestamp(),
    };

    const commonTypos = {
      // Gmail
      'gnail.com': 'gmail.com',
      'gamil.com': 'gmail.com',
      'gmai.com': 'gmail.com',
      'gmaill.com': 'gmail.com',
      'gmial.com': 'gmail.com',
      'gmal.com': 'gmail.com',

      // Yahoo
      'yaho.com': 'yahoo.com',
      'yhoo.com': 'yahoo.com',
      'yahho.com': 'yahoo.com',
      'yaoo.com': 'yahoo.com',

      // Outlook
      'outlok.com': 'outlook.com',
      'oulook.com': 'outlook.com',
      'outloo.com': 'outlook.com',
      'otulook.com': 'outlook.com',

      // iCloud
      'iclud.com': 'icloud.com',
      'iclou.com': 'icloud.com',
      'icloud.cm': 'icloud.com',
      'icould.com': 'icloud.com',
      'cloud.com': 'icloud.com',
    };

    function correctEmailDomain(email) {
      // Split the email into local part and domain part
      const [, domain] = email.split('@');

      if (domain.includes('.cm') || domain.includes('.con')) {
        return true;
      }

      // Check if the domain is in the commonTypos map
      if (commonTypos.hasOwnProperty(domain)) {
        return true;
      }

      // If no typo found, return the original email
      return false;
    }

    // Conditionally add optional fields
    const optionalFields = [
      'paypal',
      /*'cashapp',*/ 'zelle_email',
      'zelle_phone',
    ];

    optionalFields.forEach(field => {
      const value = event.target[field]?.value;
      if (value) {
        formData[field] = trimFieldValue(value);
      }
    });

    // Perform form data validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setEmailValidationMessage('Please enter a valid email address.');
      setLoading(false);
      return;
    }
    if (!emailRegex.test(formData.googleEmail)) {
      setGoogleEmailValidationMessage('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    if (correctEmailDomain(formData.email)) {
      setEmailValidationMessage('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    if (correctEmailDomain(formData.googleEmail)) {
      setGoogleEmailValidationMessage('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    const age = calculateAge(formData.dob);
    if (age < 18) {
      setAgeValidationMessage('You must be at least 18 years old.');
      setLoading(false);
      return;
    }

    // Check if the mobile number is unique
    const mobileQuery = query(
      collection(db, 'new_waitlist'),
      where('mobile', '==', formData.mobile)
    );
    const mobileQuerySnapshot = await getDocs(mobileQuery);
    if (!mobileQuerySnapshot.empty) {
      setMobileValidationMessage('Mobile number already in use.');
      setLoading(false);
      return;
    }

    // Check if the email is unique
    const emailQuery = query(
      collection(db, 'new_waitlist'),
      where('email', '==', formData.email)
    );
    const emailQuerySnapshot = await getDocs(emailQuery);
    if (!emailQuerySnapshot.empty) {
      setEmailValidationMessage('Email already in use.');
      setLoading(false);
      return;
    }

    // Perform async operations in the background
    (async () => {
      try {
        // Add the user to Firestore
        const docRef = await addDoc(collection(db, 'new_waitlist'), formData);

        // Generate the Bitly referral link
        // const getShortUrl = httpsCallable(functions, 'getShortUrl');
        // const url = `https://gofuel.ai/bounty-hunters-signup?utm_source=${docRef.id}`;
        // const bitlyResponse = await getShortUrl({ url });
        // const referralLink = bitlyResponse.data.shortUrl;

        // Update Firestore document with the referral link
        // await updateDoc(doc(db, 'new_waitlist', docRef.id), { referralLink });

        // Create Numo company CID
        // const createNumoCompanyAndInvite = httpsCallable(
        //   functions,
        //   'createNumoCompanyAndInvite'
        // );
        // const numoResponse = await createNumoCompanyAndInvite({
        //   firstName: formData.firstName,
        //   lastName: formData.lastName,
        //   email: formData.email,
        // });
        // const numoCID = numoResponse.data.companyId;
        // const numoInviteURL = numoResponse.data.inviteURL;

        // // Update Firestore document with Numo CID
        // await updateDoc(doc(db, 'new_waitlist', docRef.id), { numoCID });
        // await updateDoc(doc(db, 'new_waitlist', docRef.id), {
        //   numoInviteURL,
        // });

        // Call the Cloud Function to add data to Google Sheets
        // const addToGoogleSheet = httpsCallable(functions, 'addToGoogleSheet');
        // await addToGoogleSheet({
        //   id: docRef.id,
        //   firstName: formData.firstName,
        //   lastName: formData.lastName,
        //   email: formData.email,
        //   googleEmail: formData.googleEmail,
        //   city: formData.city,
        //   state: formData.state,
        //   zipcode: formData.zipcode,
        //   country: formData.country,
        //   gender: formData.gender,
        //   paypal: formData.paypal,
        //   // cashapp: formData.cashapp,
        //   zelle_email: formData.zelle_email,
        //   zelle_phone: formData.zelle_phone,
        //   referralLink: referralLink,
        //   numoInviteURL: numoInviteURL,
        //   numoCID: numoCID,
        // });

        // Set the form as submitted
        setIsSubmitted(true);
      } catch (e) {
        console.error('Error during form submission:', e);
        setFormErrorMessage(
          'An error occurred while submitting the form. Please try again.'
        );
        setLoading(false);
      }
    })();

    // Reset form fields
    setDob('');
    setLocation('');
    setCity('');
    setState('');
    setZip('');
    setCountry('');
    setMobile('');
    event.target.reset();
  };

  // Add a useEffect hook to handle scrolling when emailValidationMessage changes
  useEffect(() => {
    if (emailValidationMessage && emailErrorMessageRef.current) {
      emailErrorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [emailValidationMessage]);

  // Add a useEffect hook to handle scrolling when googleEmailValidationMessage changes
  useEffect(() => {
    if (googleEmailValidationMessage && googleEmailErrorMessageRef.current) {
      googleEmailErrorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [googleEmailValidationMessage]);

  // Add a useEffect hook to handle scrolling when ageValidationMessage changes
  useEffect(() => {
    if (ageValidationMessage && ageErrorMessageRef.current) {
      ageErrorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ageValidationMessage]);

  // Add a useEffect hook to handle scrolling when formErrorMessage changes
  useEffect(() => {
    if (formErrorMessage && formErrorMessageRef.current) {
      formErrorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formErrorMessage]);

  // Add a useEffect hook to handle scrolling when mobileValidationMessage changes
  useEffect(() => {
    if (mobileValidationMessage && mobileErrorMessageRef.current) {
      mobileErrorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mobileValidationMessage]);

  return (
    <section
      id='waitlistmain-section'
      className='waitlistmain-section'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      {!isSubmitted ? (
        <div className='waitlistmain-content'>
          <div className='waitlistmain-form' variants={containerVariants}>
            <p>
              <b>Notice: </b>We’re committed to giving every Bounty Hunter a
              great experience with fair earning opportunities. Due to a surge
              in interest in joining, incoming Bounty Hunters will be added to a
              waitlist. Join the waitlist to secure your spot!
            </p>
            <br />
            <form onSubmit={handleSubmit}>
              <div className='name-container' variants={itemVariants}>
                <input
                  type='text'
                  className='input name-input'
                  name='firstName'
                  placeholder='First Name *'
                  required
                  variants={itemVariants}
                />

                <input
                  type='text'
                  className='input name-input'
                  name='lastName'
                  placeholder='Last Name *'
                  required
                  variants={itemVariants}
                />
              </div>

              <input
                type='email'
                className='input'
                name='email'
                placeholder='Email *'
                required
                variants={itemVariants}
                onChange={e => {
                  setEmailValidationMessage(''); // Clear any previous validation messages
                }}
              />
              {emailValidationMessage && (
                <div className='error-message' ref={emailErrorMessageRef}>
                  {emailValidationMessage}
                </div>
              )}

              <input
                type='email'
                className='input'
                name='googleEmail'
                placeholder='Google Email *'
                required
                variants={itemVariants}
                onChange={e => {
                  setGoogleEmailValidationMessage(''); // Clear any previous validation messages
                }}
              />
              {googleEmailValidationMessage && (
                <div className='error-message' ref={googleEmailErrorMessageRef}>
                  {googleEmailValidationMessage}
                </div>
              )}

              <input
                type='tel'
                className='input'
                name='mobile'
                placeholder='Mobile Number *'
                value={mobile}
                onChange={e => {
                  const mobileNumber = e.target.value;
                  setMobile(mobileNumber);
                  setMobileValidationMessage('');
                }}
                required
                variants={itemVariants}
              />
              {mobileValidationMessage && (
                <div className='error-message' ref={mobileErrorMessageRef}>
                  {mobileValidationMessage}
                </div>
              )}

              <label htmlFor='dob'>Date of Birth *</label>
              <input
                type='date'
                className='input'
                name='dob'
                id='dob'
                value={dob}
                onChange={handleDobChange}
                required
                variants={itemVariants}
              />
              {ageValidationMessage && (
                <div className='error-message' ref={ageErrorMessageRef}>
                  {ageValidationMessage}
                </div>
              )}

              <select
                className='select'
                name='gender'
                required
                defaultValue=''
                variants={itemVariants}
              >
                <option value='' disabled>
                  Select your gender *
                </option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
                <option value='non-binary'>Non-binary</option>
                <option value='Transgender'>Transgender</option>
                <option value='Agender'>Agender</option>
                <option value='Pangender'>Pangender</option>
                <option value='Genderqueer'>Genderqueer</option>
                <option value='Two-spirit'>Two-spirit</option>
                <option value='Third gender'>Third gender</option>
                <option value='Gender neutral'>Gender neutral</option>
                <option value='Other / Prefer not to say'>
                  Other / Prefer not to say
                </option>
              </select>

              <input
                type='text'
                className='input location'
                name='location'
                placeholder='Current Street Address *'
                ref={locationInputRef}
                value={location}
                required
                variants={itemVariants}
                onChange={handleLocationChange}
              />

              <select
                className='select'
                name='country'
                value={country}
                onChange={e => setCountry(e.target.value)}
                required
                defaultValue=''
                variants={itemVariants}
              >
                <option value='' disabled>
                  Select your country *
                </option>
                {countries.map(countryOption => (
                  <option key={countryOption} value={countryOption}>
                    {countryOption}
                  </option>
                ))}
              </select>

              <input
                type='text'
                className='input city-input'
                name='city'
                placeholder='City *'
                value={city}
                onChange={e => setCity(e.target.value)}
                required
                variants={itemVariants}
              />

              <input
                type='text'
                className='input state-input'
                name='state'
                placeholder='State/Province *'
                value={state}
                onChange={e => setState(e.target.value)}
                required
                variants={itemVariants}
              />

              <input
                type='text'
                className='input zipcode-input'
                name='zipcode'
                placeholder='Zip/Post Code *'
                value={zip}
                onChange={e => setZip(e.target.value)}
                required
                variants={itemVariants}
              />

              <div
                className='checkbox-container'
                variants={itemVariants}
                style={{ marginTop: '15px' }}
              >
                <label className='checkbox-label'>
                  <input type='checkbox' name='subjectRelease' required />
                  <a
                    href='/subjectrelease'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Accept Subject Release
                  </a>
                </label>
                <label className='checkbox-label'>
                  <input type='checkbox' name='termsOfService' required />
                  <a href='/terms' target='_blank' rel='noopener noreferrer'>
                    Accept Terms of Use
                  </a>
                </label>
                <label className='checkbox-label'>
                  <input type='checkbox' name='privacyPolicy' required />
                  <a
                    href='/privacypolicy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Accept Privacy Policy
                  </a>
                </label>
                <label className='checkbox-label'>
                  <input type='checkbox' name='marketingEmails' />
                  <span>Accept Marketing Emails from Fuel AI</span>
                </label>
              </div>

              <button
                type='submit'
                disabled={loading}
                className={loading ? 'button disabled' : 'button'}
                onClick={() =>
                  logEvent(analytics, 'button_click', {
                    button_name: 'Submit Waitlist Form',
                  })
                }
              >
                Submit
              </button>
              {loading && <div className='spinner'></div>}
              {formErrorMessage && (
                <div className='error-message' ref={formErrorMessageRef}>
                  {formErrorMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div className='submission-message'>
          <h1>Thank you for signing up for the Bounty Hunter waitlist!</h1>
          <p>
            We’re working hard on getting you out on missions as soon as
            possible.
          </p>
          <ul>
            <li>
              Just for the time being, you won’t be able to finish your signup
              to become a Bounty Hunter.
            </li>
            <li>
              When a spot opens in your area, you will be notified via email.
            </li>
          </ul>
          <p>
            For more information or to learn more about being a Bounty Hunter,
            check out our{' '}
            <a
              href='/faq'
              onClick={() =>
                logEvent(analytics, 'link_click', { link_name: 'FAQ Page' })
              }
            >
              FAQ page
            </a>
          </p>
        </div>
      )}
    </section>
  );
};

export default Waitlistmain;
