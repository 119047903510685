import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import '../styles/BountyBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const BountyBanner = () => {
  let navigate = useNavigate();

  const navigateToSignup = () => {
    navigate('/waitlist');
  };

  return (
    <motion.div
      className='bounty-hunter-banner'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <div className='bounty-hunter-banner-content'>
        <h1>Welcome Bounty Hunters</h1>
        <p>
          Become a Bounty Hunter today and start earning by capturing photos,
          videos, and more. Turn your captures into cash and join the quest to
          advance AI.
        </p>
        <div className='bounty-hunter-banner-buttons'>
          <motion.button
            className='bounty-hunter-banner-button'
            onClick={navigateToSignup}
            variants={buttonVariants}
            whileHover='hover'
          >
            Join Us
          </motion.button>

          <motion.a
            className='bounty-hunter-banner-button'
            href='/faq'
            target='_blank'
            rel='noopener noreferrer'
            variants={buttonVariants}
            whileHover='hover'
          >
            FAQs
          </motion.a>
        </div>
        <ScrollLink
          to='steps-section'
          smooth={true}
          duration={500}
          className='more-info-button'
        >
          <div>
            More Information
            <div className='arrow'></div>
          </div>
        </ScrollLink>
      </div>
    </motion.div>
  );
};

export default BountyBanner;
