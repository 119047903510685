import 'bootstrap/dist/css/bootstrap.min.css';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import './App.css';
import AIBuilderPage from './Pages/AIBuilderPage';
import AboutUsPage from './Pages/AboutUsPage';
import BountyHunterPage from './Pages/BountyHunterPage';
import Faq from './Pages/Faq';
import Info from './Pages/Info'; // Import Info component
import MediaPage from './Pages/MediaPage';
import Privacy from './Pages/Privacy';
import Signup from './Pages/Signup';
import Subject from './Pages/Subject';
import Terms from './Pages/Terms';
import WaitlistPage from './Pages/Waitlist';
import AIBuilders from './components/AIBuilders';
import BountyHunter from './components/BountyHunter';
import { Footer } from './components/Footer';
import { MainBanner } from './components/MainBanner';
import { NavBar } from './components/NavBar';
import Partners from './components/Partners';
import Team from './components/Team'; // Import Team component
import { analytics } from './firebaseConfig';

function App() {
  return (
    <Router>
      <NavBar />
      <RouteChangeTracker /> {/* Add this tracker */}
      <Routes>
        <Route
          path='/'
          element={
            <>
              <MainBanner />
              <BountyHunter />
              <AIBuilders />
              <Partners />
              <Footer />
            </>
          }
        />
        <Route path='/bounty-hunters' element={<BountyHunterPage />} />
        <Route path='/ai-builders' element={<AIBuilderPage />} />
        <Route path='/about-us' element={<AboutUsPage />} />
        <Route path='/media-page' element={<MediaPage />} />
        <Route path='/bounty-hunters-signup' element={<Signup />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/subjectrelease' element={<Subject />} />
        <Route path='/waitlist' element={<WaitlistPage />} />
        <Route path='/privacypolicy' element={<Privacy />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/team' element={<Team />} /> {/* Add route for Team */}
        <Route path='/info/:memberName' element={<Info />} />{' '}
        {/* Add route for Info */}
        {/* If you have other pages, add Route components for them here */}
      </Routes>
    </Router>
  );
}

function RouteChangeTracker() {
  const location = useLocation(); // Use useLocation to access the current route

  useEffect(() => {
    // Log the page view event to Firebase Analytics
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location]);

  return null;
}

export default App;
