import { motion } from 'framer-motion';
import React from 'react';
import '../styles/WaitlistBanner.css';

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.6,
    },
  },
};

const buttonVariants = {
  hover: {
    scale: 1.1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    transition: {
      yoyo: Infinity,
      duration: 0.3,
    },
  },
};

const WaitlistBanner = () => {
  const scrollToForm = () => {
    const section = document.getElementById('waitlistmain-section');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <motion.div
      className='waitlist-banner'
      initial='hidden'
      animate='visible'
      variants={containerVariants}
    >
      <div className='waitlist-banner-content'>
        <h1>Join The Waitlist</h1>
        <p>
          We’re committed to giving every Bounty Hunter a great experience with
          fair earning opportunities. Due to a surge in interest in joining,
          incoming Bounty Hunters will be added to a waitlist. Join the waitlist
          now to secure your spot!
        </p>
        <div className='waitlist-banner-buttons'>
          <motion.button
            className='waitlist-banner-button'
            onClick={scrollToForm}
            variants={buttonVariants}
            whileHover='hover'
          >
            Click To Join
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};

export default WaitlistBanner;
