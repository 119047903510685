import React, { useEffect } from 'react';
import { Footer } from '../components/Footer';
import WaitlistBanner from '../components/WaitlistBanner';
import WaitlistMain from '../components/WaitlistMain'; // Import the BountyHunterBanner component
import { customScrollToTop } from '../helpers';
import '../styles/Waitlist.css'; // Ensure this path is correct

const Waitlist = () => {
  useEffect(() => {
    return () => {
      customScrollToTop();
    };
  }, []);

  return (
    <div className='waitlist-page'>
      <WaitlistBanner />
      <WaitlistMain />
      <Footer />
    </div>
  );
};

export default Waitlist;
